/* ListingWithImage.module.css */

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.divider {
  margin-top: 10px;
  margin-bottom: 30px;
  border-bottom: 1.5px solid rgb(172, 171, 171);
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
}

.step {
  gap: 15px;
  align-items: flex-start;
  margin-bottom: 40px;
}

.step2 {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  margin-bottom: 40px;
}

.content {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: fit-content;
  max-width: 800px;

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card:hover {
  border-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bookingContent {
  display: flex;
  align-items: center;
  width: 80%;
  cursor: pointer;
}

.bookingText {
  width: 100%;
}

.labelinputSlider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  flex-direction: row;
  @media (max-width: 767px) {
    align-items: baseline;
    flex-direction: column;
  }
}

.inputBox {
  margin-right: 8px;
  margin-left: 8px;
  width: 60px;
  padding: 4px 0px 4px 4px;
}

.cancellationText {
  width: 100%;
  font-weight: 600;
}

.cancellationContentMain {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-between;
  margin: 6px 0px 6px 0px;
}

.cancellationContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px 10px 4px;
  text-align: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

p {
  font-size: 16px;
  color: #757575;
  margin: 0;
}

.selected {
  border-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.backBtn {
  padding: 15px;
  width: 20%;
  border: 1px solid #4dcad2;
  color: #4dcad2;
  transition: opacity 0.9s;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 60px;
  font-weight: bold;
  @media (max-width: 767px) {
    width: 30%;
  }
}

.nextBtn {
  padding: 15px;
  width: 20%;
  color: white;
  background-color: #4dcad2;
  transition: opacity 0.9s;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid black;
  margin-top: 60px;
  font-weight: bold;
  @media (max-width: 767px) {
    width: 30%;
  }
}

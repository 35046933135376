/* AddressForm.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

p {
  font-size: 16px;
  /* text-align: center; */
  max-width: 600px;
  margin-bottom: 40px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 400px; */
}

label {
  font-size: 16px;
  margin-bottom: 10px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 2px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

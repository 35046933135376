/* PlaceTypeSelector.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px;
  box-sizing: border-box;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 800px;

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.card:hover {
  border-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 40px;
  height: auto;
  margin-bottom: 10px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

p {
  font-size: 16px;
  margin: 0;
  color: #424242;
}

.selected {
  border-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 24px;
  margin-bottom: 20px;
}

.fileInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.fileInput {
  margin-right: 10px;
}

.button {
  padding: 10px 20px;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.categoryContainer {
  width: 100%;
}

.categoryHeader {
  font-size: 15px;
  margin: 10px 0;
  text-align: left;
  width: 100%;
}

.imageRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}


.imageWrapper {
  position: relative;
  padding: 10px;
  margin: 10px 10px 10px 0px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 149px;
  height: 150px;
}

.imageOverlayWrapper {
  position: relative;
}

.image {
  display: block;
  width: 130px;
  height: 100px;
}

.thumbnailOverlay {
  position: absolute;
  top: -8px;
  left: -8px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
}

.ellipsisButton {
  position: absolute;
  top: -14px;
  right: -9px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.dropdownContent {
  display: none;
  position: absolute;
  top: 3px;
  right: 3px;
  height: 50px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.delete {
  color: red;
}

.imageOverlayWrapper:hover .dropdownContent {
  display: block;
}

.dropdownContent button {
  display: block;
  padding: 5px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: 10px;
}

.select {
  margin: 10px;
  width: calc(100% - 20px);
  font-size: 10px !important;
  border-radius: 5px;
}

/* PropertyPhotos.module.css */

.uploadContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  margin-top: 20px;
  border-color: grey;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  border-color: grey;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.selectedThumbnail {
  border: 4px solid #4dcad2;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

p {
  font-size: 16px;
  /* text-align: center; */
  max-width: 600px;
}

form.upload-container {
  flex: 1;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  cursor: pointer;

  border-color: grey;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    margin-bottom: 0px;
  }
}

label {
  font-size: 16px;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 2px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.uploadedImages {
  margin-top: 40px;
}

.ImgWrapper {
  position: relative;
}

.PropertyImg {
  height: 80px;
  width: 80px;
  margin: 20px;
}

.ImgClose {
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  cursor: pointer;
  background: white;
  height: 18px;

  img {
    background: white;
    height: 21px;
  }
}


@media (max-width: 600px) {
  .imageWrapper {
      width: 44%;
  }
}

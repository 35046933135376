/* PlaceAccessTypeSelector.module.css */

.container {
  box-sizing: border-box;
  padding: 40px 10px 40px 10px;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.option {
  width: 100%;
  max-width: 800px;
  border-bottom: 2px solid #e0e0e0;
  padding: 20px;
  text-align: left;
  margin-bottom: 15px;
  position: relative;
  background: #ffffff;
}

.iconText {
  display: flex;
  gap: 15px;
}

.text h2 {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}

.text p {
  font-size: 16px;
  color: #424242;
  margin: 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  width: 100%;
}

.detail {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.addressItem {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.thumbnailOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #333;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.addressText {
  width: 50%;
  font-size: 16px;
  color: #555;
  word-wrap: break-word;
  max-width: 400px;
}

.marginLeft10 {
  margin-left: 10px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.marginTop10 {
  margin-top: 10px;
  margin-left: 15px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 800px;
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: center;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.backBtn {
  padding: 15px;
  width: 20%;
  border: 1px solid #4dcad2;
  color: #4dcad2;
  transition: opacity 0.9s;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 60px;
  font-weight: bold;
  @media (max-width: 767px) {
    width: 30%;
  }
}

.nextBtn {
  padding: 15px;
  width: 20%;
  color: white;
  background-color: #4dcad2;
  transition: opacity 0.9s;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid black;
  margin-top: 60px;
  font-weight: bold;
  @media (max-width: 767px) {
    width: 30%;
  }
}
.nextBtn:disabled {
  cursor: not-allowed;
}

.completeMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.completeIcon {
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
}

.completeMessage h1 {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
}

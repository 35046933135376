.container {
  display: flex;
  padding: 40px 60px;
  @media (max-width: 750px) {
    padding: 40px 30px 0px 30px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-right: 60px;

  @media (max-width: 750px) {
    padding: 20px 0px;
    text-align: center;
    width: 100%;
  }
}

h1 {
  font-size: 30px;
  font-weight: 600;
}

.trip-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 100px;
  width: 100%;

  @media (max-width: 750px) {
    padding: 20px 0px;
    text-align: center;
    width: 100%;
  }
}

.trip-item,
.payment-method,
.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 20px;
  @media (max-width: 750px) {
    align-items: baseline;
  }
}

.label {
  font-weight: bold;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.edit-link:hover {
  text-decoration: underline;
}

.pay-btn {
  width: 50%;
  background-color: #4dcad2;
  color: white;
  border: none;
  padding: 24px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
  @media (max-width: 750px) {
    width: 100%;
  }
}

.pay-btn:hover {
  opacity: 0.8 !important;
  background-color: #4dcad2 !important;
}

.payment-select,
.payment-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sidebar {
  position: relative;
  width: 40%;
  margin-right: 40px;
}

.sticky-card {
  position: sticky;
  top: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card-image {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
}

.card-details h3 {
  text-align: center;
  margin: 12px 0 8px 0;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}

.pricing {
  margin-top: 10px;
  padding-top: 10px;
}

.price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.total {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .content {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .sidebar {
    width: 100%;
  }

  .sticky-card {
    position: static;
  }
}

/* @media (max-width: 414px) {
  .trip-item,
  .payment-method,
  .price-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .edit-link {
    align-self: flex-end;
    margin-top: 5px;
  }

  .total {
    flex-direction: column;
    align-items: flex-start;
  }
} */

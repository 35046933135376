.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* background-color: white; */
}

.usergreeting {
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: 550;
  color:rgb(30, 136, 229);
  margin-right: 0px;
}

.usericon {
  margin-right: 8px;
  font-size: 24px;
  color: #333;
}

.profileMenuContainer {
  position: relative;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 120%;
  right: -58px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  z-index: 1000;
  width: 180px;
  padding: 10px 0;
}

.menuItem {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.menuItem:hover {
  background-color: #f0f0f0;
}
